import { RegisterStepCredentialsLeftPart } from './left/RegisterStepCredentialsLeftPart.tsx'
import { RegisterStepCredentialsRightPart } from './right/RegisterStepCredentialsRightPart.tsx'

export function CredentialsStep() {
  return (
    <div className={'flex w-full flex-shrink-0 items-start gap-[1.5rem] xl:w-[81.875rem] xl:gap-0'}>
      <RegisterStepCredentialsLeftPart />
      <RegisterStepCredentialsRightPart />
    </div>
  )
}
