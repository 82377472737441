import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconHourGlass(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.209 12.665L7.9361 9.1043C7.30125 8.57526 6.98382 8.31074 6.75562 7.98648C6.55343 7.69917 6.40327 7.37858 6.312 7.03932C6.20898 6.65643 6.20898 6.24323 6.20898 5.41684V2.66504M12.209 12.665L16.4819 9.1043C17.1167 8.57526 17.4341 8.31074 17.6623 7.98648C17.8645 7.69917 18.0147 7.37858 18.106 7.03932C18.209 6.65643 18.209 6.24323 18.209 5.41684V2.66504M12.209 12.665L7.9361 16.2258C7.30125 16.7548 6.98382 17.0193 6.75562 17.3436C6.55343 17.6309 6.40327 17.9515 6.312 18.2908C6.20898 18.6737 6.20898 19.0868 6.20898 19.9132V22.665M12.209 12.665L16.4819 16.2258C17.1167 16.7548 17.4341 17.0193 17.6623 17.3436C17.8645 17.6309 18.0147 17.9515 18.106 18.2908C18.209 18.6737 18.209 19.0868 18.209 19.9132V22.665M4.20898 2.66504H20.209M4.20898 22.665H20.209"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
