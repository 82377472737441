import { TheIconAvatarAI } from '@/components/icons/TheIconAvatarAI.tsx'
import ReactMarkdown from 'react-markdown'
import { TheAddedDocumentTag } from '@/components/TheAddedDocumentTag.tsx'
import { nanoid } from '@reduxjs/toolkit'
import { useConversationPageHook } from '@/pages/conversations/[conversationId]/use-conversation-page.hook.tsx'

export function ConversationSystemMessageWithDocuments() {
  const { conversation, systemMessageContent } = useConversationPageHook()
  return (
    <div className={`flex w-full flex-row rounded-lg bg-brightGray p-3 sm:justify-normal lg:ml-auto lg:w-fit lg:gap-4`}>
      <TheIconAvatarAI className={'ml-2 h-[45px] w-[45px]'} />

      <div className={'flex flex-col gap-4 text-center'}>
        <ReactMarkdown className={`prose text-center text-sm leading-8 sm:text-left`}>
          {systemMessageContent}
        </ReactMarkdown>
        <div className={'flex flex-col justify-between gap-2 lg:flex-row'}>
          {conversation?.currentFilesInfos.map((fileInfos) => (
            <TheAddedDocumentTag
              key={nanoid()}
              fileInfos={{
                ...fileInfos,
                uploadStatus: 'UPLOADED',
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
