import { useState } from 'react'
import { useAppDispatch } from '../store/hooks.ts'
import { Toaster, closeToaster, setToasterMessage } from '../store/toasterSlice.ts'

export function useToaster(durantionInMs = 3000) {
  const dispatch = useAppDispatch()
  const [displayToaster, setDisplayToaster] = useState(false)

  const showToast = ({ toaster, timeOut }: { toaster: Toaster; timeOut?: boolean }) => {
    setDisplayToaster(true)
    dispatch(setToasterMessage(toaster))
    if (timeOut === false) return
    setTimeout(() => {
      close()
    }, durantionInMs)
  }

  const close = () => {
    setDisplayToaster(false)
    dispatch(closeToaster())
  }
  return {
    displayToaster,
    showToast,
    close,
  }
}
