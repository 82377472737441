import { PaymentModal } from '@/components/modals/PaymentModal.tsx'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'
import { DialogTitle } from '@/components/ui/dialog.tsx'
import { PaymentModalContactFooter } from '@/components/PaymentModalContactFooter.tsx'
import { PaymentModalPricingSectionContainer } from '@/components/PaymentModalPricingSectionContainer.tsx'
import { useAppDispatch } from '@/store/hooks.ts'
import { logoutUser } from '@/store/auth/useCases/logoutUser.ts'
import { Button } from '@/components/ui/button.tsx'
import { openCalendlyModal } from '@/store/modal/modalsSlice.ts'

export function PaymentModalContainer() {
  const { isOpenPaymentModal } = useCurrentUserHook()
  const dispatch = useAppDispatch()

  const onLogoutUser = async () => {
    await dispatch(logoutUser())
  }

  const onOpenCalendlyModal = async () => {
    dispatch(openCalendlyModal())
  }

  return (
    <PaymentModal isOpen={isOpenPaymentModal}>
      <DialogTitle className={'mb-5 text-center text-[1.75rem] font-semibold leading-9 text-primary'}>
        Olympe devient payant !
      </DialogTitle>
      <section className={'space-y-8 text-center'}>
        <PaymentModalPricingSectionContainer />
        <div className={'flex flex-col gap-4'}>
          <p className={'text-base font-normal'}>
            Vous préférez <span className={'font-semibold'}>demander un devis</span>, régler par{' '}
            <span className={'font-semibold'}>virement</span>, ou bien prendre RDV pour une{' '}
            <span className={'font-semibold'}>démo</span> ?
          </p>
          <Button variant={'tertiary'} onClick={onOpenCalendlyModal}>
            Je prends RDV
          </Button>
        </div>
        <PaymentModalContactFooter onClick={onLogoutUser} />
      </section>
    </PaymentModal>
  )
}
