import { Button } from '@/components/ui/button.tsx'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'
import { PaymentModalPricingCard } from '@/components/PaymentModalPricingCard.tsx'

export type Pricing = {
  title: 'REGULAR' | 'EARLY ADOPTER' | 'ABONNEMENT'
  monthlyPriceExclTax: number
  isDisabled?: boolean
  description?: string
  link?: string
}

const stripePaymentLink = import.meta.env.VITE_STRIPE_PAYMENT_LINK
const prefilledPromoCode = import.meta.env.VITE_STRIPE_PROMO_CODE

export function PaymentModalPricingSectionContainer() {
  const { currentUser } = useCurrentUserHook()
  const getPromoCode = () => {
    if (!prefilledPromoCode) return ''
    return `+&prefilled_promo_code=${prefilledPromoCode}`
  }

  const stripeLinkWithQueryParams = `${stripePaymentLink}?prefilled_email=${currentUser?.email}${getPromoCode()}`

  const pricingList: Pricing[] = [
    {
      title: 'ABONNEMENT',
      monthlyPriceExclTax: 99,
      link: stripeLinkWithQueryParams,
    },
  ]

  return (
    <div className={'space-y-4'}>
      {pricingList.map((pricing) => (
        <div className={'flex flex-col gap-5'} key={pricing.title}>
          <PaymentModalPricingCard {...pricing} />
          <Button className={'text-base'} asChild>
            <a href={pricing.link} target={'_blank'} rel="noopener noreferrer" className={'block hover:cursor-pointer'}>
              {"Je m'abonne"}
            </a>
          </Button>
        </div>
      ))}
    </div>
  )
}
