import { Link, useNavigate } from 'react-router-dom'
import { BaseButton } from '../../components/atomics/BaseButton.tsx'
import BaseInput from '../../components/atomics/BaseInput.tsx'
import { TheIconHideEye } from '../../components/icons/TheIconHideEye.tsx'
import { TheIconOlympeLogoWithText } from '../../components/icons/TheIconOlympeLogoWithText.tsx'
import { TheIconOlympeLogoWithoutText } from '../../components/icons/TheIconOlympeLogoWithoutText.tsx'
import { TheIconVisibleEye } from '../../components/icons/TheIconVisibleEye.tsx'
import FooterLink from '../../components/registerSteps/FooterLink.tsx'
import { getErrorMessages } from '@/utils/formValidation.ts'
import LeftScreenSection from './LeftScreenSection.tsx'
import { useLoginHook } from './use-login.hook.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import { useEffect } from 'react'
import { WretchAccountGateway } from '@/gateways/WretchAccount.gateway.ts'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'
import { setCurrentUser } from '@/store/auth/authSlice.ts'
import { simpleGPTApiWretch } from '@/gateways/olympeGptApiWretch.ts'
import { FooterLinks } from '@/components/FooterLinks.tsx'

export function Login() {
  const {
    form,
    enableLoginFromParentWindow,
    handleSubmit,
    handleEmailChange,
    handlePasswordChange,
    emailErrors,
    passwordInputType,
    passwordSimpleErrors,
    togglePasswordInputType,
    handleEmailValidation,
    handlePasswordValidation,
  } = useLoginHook()

  enableLoginFromParentWindow()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    fetchUser()
    async function fetchUser() {
      const accountGateway = new WretchAccountGateway(simpleGPTApiWretch)
      const currentUser = await accountGateway.getCurrentUser()
      if (currentUser?.emailValidated === false) {
        navigate(`${ROUTE_PATHS.accountVerificationInfo}?email=${currentUser.email}`)
      }
      if (currentUser.emailValidated) {
        dispatch(setCurrentUser(currentUser))
        navigate(ROUTE_PATHS.greetingUser)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'relative flex h-screen p-7'}>
      <div className={`hidden w-7/12 flex-col gap-14 overflow-auto rounded-2xl bg-gradient-301 pl-8 pr-7 pt-6 sm:flex`}>
        <div>
          <TheIconOlympeLogoWithText className={'fill-primary'} />
        </div>
        <LeftScreenSection />
      </div>
      <div
        className={
          'flex h-full w-full flex-col items-center gap-8 pt-8 sm:w-5/12 sm:px-4 md:pl-8 md:pr-6 lg:px-20 xl:px-20'
        }
      >
        <div className={'flex h-[95%] w-full max-w-[500px] flex-col items-center justify-center gap-8'}>
          <div className={'flex h-full w-full flex-col'}>
            <div className={'mb-7 flex flex-col items-center gap-4'}>
              <TheIconOlympeLogoWithoutText width="41" height="40" className={'fill-primary'} />
              <h1 className="h-14 text-center text-4xl font-semibold text-primary">Connexion</h1>
            </div>
            <div className={'flex flex-col gap-7 overflow-auto'}>
              <form
                data-testid="login-form"
                className={'mb-12 flex h-2/3 flex-col gap-4'}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    handleSubmit(e)
                  }
                }}
              >
                <BaseInput
                  placeholder="Adresse email"
                  name="email"
                  label="Adresse email"
                  type="email"
                  value={form.email}
                  onChange={handleEmailChange}
                  onBlur={handleEmailValidation}
                  errormessage={getErrorMessages(emailErrors)[0]}
                />
                <BaseInput
                  placeholder="Mot de passe"
                  name="password"
                  label="Mot de passe"
                  type={passwordInputType}
                  value={form.password}
                  onChange={handlePasswordChange}
                  onBlur={handlePasswordValidation}
                  errormessage={getErrorMessages(passwordSimpleErrors)[0]}
                >
                  {passwordInputType === 'password' ? (
                    <TheIconHideEye
                      data-testid={'icon-eye'}
                      className="w-6 fill-primary hover:cursor-pointer"
                      onClick={togglePasswordInputType}
                    />
                  ) : (
                    <TheIconVisibleEye
                      className="w-6 fill-primary hover:cursor-pointer"
                      onClick={togglePasswordInputType}
                    />
                  )}
                </BaseInput>
                <div className="flex-flex-col self-end">
                  <Link to="/reset-password" className="hover:opacity-70">
                    <p className="text-sm font-normal text-label">Mot de passe oublié ?</p>
                  </Link>
                </div>
              </form>
              <div className={'mx-1 flex flex-col gap-4 sm:mx-0 sm:gap-10'}>
                <BaseButton className="w-2/3 self-center" label="Connexion" onClick={handleSubmit}>
                  Connexion
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
        <div className={'flex flex-col items-center gap-10'}>
          <FooterLinks
            onClick={() => navigate('/register')}
            catchphrase={'Vous n’avez pas de compte ?'}
            linkLabel={'Créez un compte'}
          />
          <FooterLink />
        </div>
      </div>
    </div>
  )
}
