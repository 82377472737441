import { useForm } from 'react-hook-form'
import {
  RegistrationFormValues,
  registrationSchema,
} from '@/components/registerSteps/credentials/right/registrationSchema.ts'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form.tsx'
import { Input } from '@/components/ui/input.tsx'
import { Button } from '@/components/ui/button.tsx'

interface Props {
  onSubmit: (values: RegistrationFormValues) => void
}

export function SigninForm({ onSubmit }: Readonly<Props>) {
  const form = useForm<RegistrationFormValues>({
    resolver: zodResolver(registrationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onBlur',
  })

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className={'space-y-1'}>
              <FormLabel className={'text-label'}>Email</FormLabel>
              <FormControl>
                <Input placeholder="Renseignez votre email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem className={'space-y-1'}>
              <FormLabel className={'text-label'}>Mot de passe</FormLabel>
              <FormControl>
                <Input type="password" placeholder="Renseignez un mot de passe" {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <div className={'flex items-center justify-center'}>
          <Button type="submit" className={'rounded-lg px-3 py-6 text-sm leading-5 text-bright'}>
            Connexion
          </Button>
        </div>
      </form>
    </Form>
  )
}
