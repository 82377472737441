import { Tag } from '@/types/tag.ts'
import { FileData, FileDataWithExtraColumn, UploadedFile } from '@/types/file.ts'
import { applyTags } from '@/store/tags/useCases/applyTags.ts'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { useToaster } from '@/utils/useToaster.ts'
import React, { ChangeEvent, FormEvent, RefObject, useEffect, useRef, useState } from 'react'
import { TableData } from '../../components/atomics/BaseTable.tsx'
import { deleteManyFiles } from '@/store/files/useCases/deleteManyFiles.ts'
import { selectAllFiles, selectFilesByTagId, selectIsUploading } from '@/store/files/filesSlice.ts'
import { deleteFileById } from '@/store/files/useCases/deleteFileById.ts'
import { FilterKey } from '../../components/TheMobileFileFilterPanel.tsx'
import { FileNameErrors, validateFileName } from '@/utils/formValidation.ts'
import { uploadFile } from '@/store/files/useCases/uploadFile.ts'
import { updateFile } from '@/store/files/useCases/updateFile.ts'
import { selectCurrentTag, selectFileCountByTag, selectTagWithoutDefaultAndCurrent } from '@/store/tags/tagSlice.ts'
import { useSelector } from 'react-redux'
import { useFilePreviewModalHook } from '../../components/modals/hooks/use-filePreviewModal.hook.tsx'
import { useKeyPressEvent } from '@/utils/useKeyPressEvent.ts'
import { formatDateFileData } from '@/utils/formatDateFileData.ts'
import { Toaster } from '@/store/toasterSlice.ts'

export type ExpendablesSections = {
  isSortActionsSectionOpened: boolean
  isMoveActionsSectionOpened: boolean
}

export const fileNameErrorsInitialState: FileNameErrors = { emptyFileName: '' }

export const expendablesSectionsInitial: ExpendablesSections = {
  isSortActionsSectionOpened: false,
  isMoveActionsSectionOpened: false,
}

export const useDocumentsHook = () => {
  const dispatch = useAppDispatch()
  const allUserFiles = useAppSelector(selectAllFiles)
  const currentTag = useAppSelector(selectCurrentTag)
  const filesByTag = useAppSelector(selectFilesByTagId(currentTag.id))
  const tags = useAppSelector(selectTagWithoutDefaultAndCurrent)
  const fileCountByTag = useSelector(selectFileCountByTag)
  const isUploading = useAppSelector(selectIsUploading)
  const { previewFile, handleFilePreview } = useFilePreviewModalHook()
  const { handleKeyDown } = useKeyPressEvent()
  const { showToast } = useToaster()
  const [selectedRows, setSelectedRows] = useState<TableData<UploadedFile>>([])
  const [selectedItem, setSelectedItem] = useState<FileData | null>(null)
  const [expendablesSections, setExpendablesSections] = useState(expendablesSectionsInitial)
  const [file, setFile] = useState<FileData & { size: string }>({
    createdAt: '',
    extension: '',
    id: '',
    tags: [],
    title: '',
    size: '',
  })

  const date = useRef<HTMLButtonElement>(null)
  const extension = useRef<HTMLButtonElement>(null)
  const alphabeticOrder = useRef<HTMLButtonElement>(null)
  const [isBulkTagSelectVisible, setIsBulkTagSelectVisible] = useState<boolean>(false)
  const [isBulkModalVisible, setIsBulkModalVisible] = useState(false)
  const [filterKey, setFilterKey] = useState<FilterKey>('Date')
  const [isRowModalVisible, setIsRowModalVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [fileNameErrors, setFileNameErrors] = useState<FileNameErrors>(fileNameErrorsInitialState)
  const [displayLoading, setDisplayLoading] = useState(false)
  const [isRenameTagModalVisible, setIsRenameTagModalVisible] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [emptyArrayMessage, setEmptyArrayMessage] = useState('')
  const [filteredData, setFilteredData] = useState<TableData<FileDataWithExtraColumn>>([])

  useEffect(() => {
    const formattedDataFromAllFiles = formatDateFileData(allUserFiles)
    const formattedDataFromFilesFilteredByTagId = formatDateFileData(filesByTag)
    const fileDataList: FileData[] =
      currentTag.id === 0 ? formattedDataFromAllFiles : formattedDataFromFilesFilteredByTagId
    setFilteredData(fileDataList as TableData<FileDataWithExtraColumn>)
    setSearchValue('')
    if (!allUserFiles.length || !filesByTag.length) {
      setEmptyArrayMessage('Vous n’avez pas de document dans votre dossier.')
    }
  }, [currentTag, allUserFiles]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleApplyTags = async (tag: Tag, files: FileData[]) => {
    const messages = {
      singleAlreadyInFolder: 'Le document est déjà dans le dossier.',
      multipleAlreadyInFolder: 'Les documents sont déjà dans le dossier.',
      singleMoved: (tag: Tag) => (
        <>
          Votre document a été déplacé vers le dossier <span className={'font-semibold'}>{tag.name}.</span>
        </>
      ),
      multipleMoved: (count: number, tag: Tag) => (
        <>
          Vos {count} documents ont été déplacés vers le dossier <span className={'font-semibold'}>{tag.name}.</span>
        </>
      ),
      error: "Une erreur s'est produite. Veuillez réessayer.",
    }

    const { filesMoved, alreadyInFolder } = files.reduce<{
      filesMoved: ReturnType<typeof dispatch>[]
      alreadyInFolder: FileData['id'][]
    }>(
      (acc, file) => {
        const tagsToAdd: Tag['id'][] = [tag.id]
        const tagsToRemove: Tag['id'][] = file.tags[0] ? [file.tags[0]] : []

        if (
          tagsToAdd.every((element) => tagsToRemove.includes(element)) &&
          tagsToRemove.every((element) => tagsToAdd.includes(element))
        ) {
          acc.alreadyInFolder.push(file.id)
        } else {
          acc.filesMoved.push(dispatch(applyTags({ fileId: file.id, tagsToAdd, tagsToRemove })))
        }

        return acc
      },
      { filesMoved: [], alreadyInFolder: [] },
    )

    if (alreadyInFolder.length > 0) {
      const message = alreadyInFolder.length === 1 ? messages.singleAlreadyInFolder : messages.multipleAlreadyInFolder
      const toaster: Toaster = {
        toasterMessage: message,
        toasterType: 'success',
      }
      showToast({ toaster })
    }

    if (filesMoved.length > 0) {
      try {
        await Promise.all(filesMoved)
        const movedMessage =
          filesMoved.length === 1 ? messages.singleMoved(tag) : messages.multipleMoved(filesMoved.length, tag)
        const toaster: Toaster = {
          toasterMessage: movedMessage,
          toasterType: 'success',
        }
        showToast({ toaster })
      } catch (error) {
        const toaster: Toaster = {
          toasterMessage: messages.error,
          toasterType: 'identified',
        }
        showToast({
          toaster,
        })
      }
    }

    setSelectedItem(null)
    setExpendablesSections({
      isSortActionsSectionOpened: false,
      isMoveActionsSectionOpened: false,
    })
    setSelectedRows([])
  }

  const handleDeleteDocuments = () => {
    const ids = selectedRows.map((file) => {
      return file.id
    })
    dispatch(deleteManyFiles(ids))
    setSelectedRows([])
  }

  const deleteFileByFileId = (file: FileData & { size: string }) => {
    setSelectedRows(selectedRows.filter((row: { id: string }) => row.id !== file.id))
    dispatch(deleteFileById(file.id))
  }

  const toggleRowSelection = (item: FileData) => {
    if (selectedRows.includes(item)) {
      setSelectedRows(selectedRows.filter((row) => row !== item))
    } else {
      setSelectedRows([...selectedRows, item])
    }
  }

  const handleSetSelectedItem = (item: FileData) => {
    selectedItem === null || item.id !== selectedItem.id ? setSelectedItem(item) : setSelectedItem(null)
  }

  const toggleBulkModal = () => {
    setIsBulkModalVisible(!isBulkModalVisible)
    setExpendablesSections(expendablesSectionsInitial)
  }

  const toggleRowModal = () => {
    setIsRowModalVisible(!isRowModalVisible)
    setExpendablesSections(expendablesSectionsInitial)
  }

  const toggleSingleFileActionsModal = (file: FileData) => {
    toggleRowModal()
    setFile({ ...file, size: '' })
  }

  const handleDeleteAllDocumentsOnMobile = () => {
    toggleBulkModal()
    const ids = allUserFiles.map((file) => file.id)
    dispatch(deleteManyFiles(ids))
  }

  const toggleAction = (action: keyof typeof expendablesSections) => {
    setExpendablesSections({
      ...expendablesSections,
      [action]: !expendablesSections[action],
    })
  }

  const setIsActive = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLLIElement

    setFilterKey(target.id as FilterKey)
    toggleBulkModal()
  }

  const getFontStyleBasedOnCurrentRef = (ref: RefObject<HTMLButtonElement>) => {
    if (!ref) {
      return
    }
    return ref?.current?.id === filterKey ? 'font-semibold text-primary' : 'font-normal text-label'
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFileNameErrors(validateFileName(inputValue))
    if (inputValue.length === 0) return
    handleUpdateDocument(inputValue, file as unknown as FileDataWithExtraColumn)
    toggleRenameFileModal()
    setInputValue('')
  }

  const handleFileInputChange = async (e: FormEvent<HTMLInputElement>) => {
    const inputElement = e.target as HTMLInputElement
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0]
      const titleParts = file.name.split('.')
      const extension = titleParts.length > 1 ? `.${titleParts[titleParts.length - 1].toUpperCase()}` : ''
      setFile({
        createdAt: '',
        extension,
        id: '',
        tags: [],
        title: file.name,
        size: file.size.toString(),
      })
      await upLoadFile(file)
    }
  }

  const upLoadFile = async (file: File) => {
    setDisplayLoading(true)
    const tags = currentTag?.id === 0 ? [] : [currentTag?.id]
    const uploadFileResponse = await dispatch(uploadFile({ file, title: file.name, tags }))
    setTimeout(() => {
      setDisplayLoading(false)
    }, 1000)
    if (uploadFile.fulfilled.match(uploadFileResponse)) {
      const toaster: Toaster = {
        toasterMessage: `Votre document a été ajouté au dossier "${currentTag.name}"`,
        toasterType: 'success',
      }
      showToast({
        toaster,
      })
    }
  }

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value)
    setFileNameErrors(validateFileName(e.currentTarget.value))
  }

  const deleteFileByRowIndex = (rowIndex: number) => {
    const file = allUserFiles[rowIndex]
    dispatch(deleteFileById(file.id))
  }

  const toggleRenameFileModal = () => {
    setIsRenameTagModalVisible(!isRenameTagModalVisible)
    setFileNameErrors(fileNameErrorsInitialState)
  }

  const handleSelectAll = (filesFromTable: FileData[]) => {
    if (selectedRows.length === filesFromTable.length) {
      setSelectedRows([])
    } else {
      setSelectedRows(filesFromTable)
    }
  }

  const filterDataByInputValue = (data: typeof allUserFiles, inputValue: string) => {
    return data.filter((item) => {
      const itemWithoutId: Partial<typeof item> = { ...item }
      delete itemWithoutId.id
      const isInputValueIncluded = Object.values(itemWithoutId).some((el) =>
        String(el).toLowerCase().includes(inputValue),
      )
      return isInputValueIncluded || null
    })
  }

  const filterFiles = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    const inputValue = e.target.value.toLowerCase()
    const filteredDataFromAllFiles = filterDataByInputValue(allUserFiles, inputValue)
    const filteredDataFromFilesFilteredByTagId = filterDataByInputValue(filesByTag, inputValue)

    setEmptyArrayMessage('Nous n’avons trouvé aucun document correspondant à votre recherche.')
    currentTag.id === 0
      ? setFilteredData(filteredDataFromAllFiles as TableData<FileDataWithExtraColumn>)
      : setFilteredData(filteredDataFromFilesFilteredByTagId as TableData<FileDataWithExtraColumn>)
  }

  const handleUpdateDocument = (value: string, document: FileDataWithExtraColumn | null) => {
    if (!document) return
    const extension = document.title.split('.')[1]
    const newTitleWithExtension = `${value}.${extension}`
    dispatch(updateFile({ id: document.id, title: newTitleWithExtension }))
  }

  const handleClearSearchValue = () => {
    setSearchValue('')
    currentTag.id === 0
      ? setFilteredData(allUserFiles as TableData<FileDataWithExtraColumn>)
      : setFilteredData(filesByTag as TableData<FileDataWithExtraColumn>)
  }

  const handleCancel = () => {
    toggleRenameFileModal()
    setInputValue('')
  }

  return {
    handleApplyTags,
    handleDeleteDocuments,
    deleteFileByFileId,
    toggleRowSelection,
    selectedItem,
    setSelectedItem,
    handleSetSelectedItem,
    expendablesSections,
    isBulkModalVisible,
    toggleBulkModal,
    toggleAction,
    handleDeleteAllDocumentsOnMobile,
    isRowModalVisible,
    toggleRowModal,
    setIsActive,
    getFontStyleBasedOnCurrentRef,
    filterKey,
    file,
    toggleSingleFileActionsModal,
    setFilterKey,
    selectedRows,
    handleFilePreview,
    date,
    extension,
    alphabeticOrder,
    tags,
    files: allUserFiles,
    toggleRenameFileModal,
    isRenameTagModalVisible,
    handleSubmit,
    handleInputChange,
    inputValue,
    fileNameErrors,
    handleCancel,
    displayLoading,
    isUploading,
    handleKeyDown,
    fileCountByTag,
    setSelectedRows,
    previewFile,
    handleFileInputChange,
    filterFiles,
    handleClearSearchValue,
    searchValue,
    emptyArrayMessage,
    filteredData,
    handleUpdateDocument,
    handleSelectAll,
    deleteFileByRowIndex,
    isBulkTagSelectVisible,
    setIsBulkTagSelectVisible,
    currentTagName: currentTag.name.charAt(0).toUpperCase() + currentTag.name.slice(1),
  }
}
