import { cn } from '@/lib/utils.ts'

interface PricingCardProps {
  title: string
  monthlyPriceExclTax: number
}
export function PaymentModalPricingCard({ title, monthlyPriceExclTax }: Readonly<PricingCardProps>) {
  return (
    <div
      className={cn(
        'flex cursor-default flex-col items-center justify-center rounded-2xl border border-primary pb-5 pt-4',
      )}
    >
      <h2 className={cn('text-sm font-normal text-primary')}>{title}</h2>
      <p className={cn('text-2xl font-semibold text-primary')}>
        {monthlyPriceExclTax}€ <span className={cn('text-sm font-semibold text-primary')}>TTC</span> / mois
      </p>
      <span className={cn('text-base font-semibold leading-5 text-primary')}>sans engagement</span>
    </div>
  )
}
