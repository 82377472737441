import { ADDITIONAL_WAIT_TIME } from '@/utils/CONVERSATION_CONSTANTS.ts'
import { useEffect, useState } from 'react'
import { selectUser } from '../../store/auth/authSlice.ts'
import { useAppSelector } from '../../store/hooks.ts'
import { useConversationPageHook } from '../conversations/[conversationId]/use-conversation-page.hook.tsx'
import { useSearchParams } from 'react-router-dom'
import { CALLBACK_URL_SEARCH_PARAMS } from '@/ROUTE_PATHS.ts'

export function useGreetingUserHook() {
  const [searchParams] = useSearchParams()
  const callbackUrl = searchParams.get(CALLBACK_URL_SEARCH_PARAMS)

  const [name, setName] = useState('')
  const currentUser = useAppSelector(selectUser)
  const { navigateToFirstConversationOrCreateOneIfNoneExists } = useConversationPageHook()

  useEffect(() => {
    setName(currentUser?.firstname ?? '')
    async function redirect() {
      if (callbackUrl) {
        window.location.href = callbackUrl
        return
      }
      await navigateToFirstConversationOrCreateOneIfNoneExists()
    }
    const timeout = setTimeout(redirect, ADDITIONAL_WAIT_TIME)
    return () => {
      clearTimeout(timeout)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const capitalizeName = (): string => {
    if (!name) return ''
    return name[0].toUpperCase() + name.slice(1)
  }

  return { capitalizedName: capitalizeName() }
}
