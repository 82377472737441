import { Toaster } from '../../store/toasterSlice.ts'

export const useToasterHook = ({
  toasterType,
  toasterPosition,
}: {
  toasterType: Toaster['toasterType']
  toasterPosition?: 'top' | 'bottom' | null
}) => {
  const renderBgColor = (toasterType: Toaster['toasterType']) => {
    return toasterType === 'success' || toasterType === 'trialDays' ? 'bg-mintMist' : 'bg-rose-100'
  }

  const renderPosition = (toasterType: Toaster['toasterType']) => {
    if (toasterPosition) return toasterPosition === 'top' ? 'top-10' : 'bottom-10'
    return toasterType === 'success' ? 'bottom-10' : 'top-10'
  }
  return {
    backgroundColor: renderBgColor(toasterType),
    position: renderPosition(toasterType),
  }
}
