import { TheIconOlympeLogoWithoutText } from '../../../icons/TheIconOlympeLogoWithoutText.tsx'
import { useCredentialsStepHook } from './use-credentialsStep.hook.tsx'
import { SignupForm } from '@/components/SignupForm.tsx'
import { FooterLinks } from '@/components/FooterLinks.tsx'
import { useAuthFormModal } from '@/components/modals/hooks/use-authFormModal.tsx'
import { useNavigate } from 'react-router-dom'

export function RegisterStepCredentialsRightPart() {
  const navigate = useNavigate()
  const { navigationStyle, headerStyle } = useCredentialsStepHook()
  const { onSignupSubmit } = useAuthFormModal()

  return (
    <div
      className={'flex h-[44.5rem] flex-col items-center justify-center gap-4 xl:flex-[1_0_0] xl:p-[2rem_7rem_0_7rem]'}
    >
      <div className={'flex w-full flex-col items-center gap-5 self-stretch xl:w-[28.2rem] xl:flex-[1_0_0]'}>
        <div className={`flex flex-col items-center gap-4 ${headerStyle} self-stretch`}>
          <div className={`flex w-full flex-col items-start gap-4`}>
            <TheIconOlympeLogoWithoutText width="41" height="40" />
            <div className={'flex flex-col items-start gap-3 self-stretch'}>
              <h1 className="text-4xl font-semibold text-primary">
                Renforcez votre expertise en protection des données
              </h1>
              <h1 className="text-2xl font-normal text-primary">Commencez votre essai gratuit de 15 jours</h1>
            </div>
          </div>
          <SignupForm onSubmit={onSignupSubmit} />
        </div>
        <div className={`flex flex-col items-center justify-center ${navigationStyle} self-stretch`}>
          <FooterLinks
            onClick={() => navigate('/login')}
            catchphrase={'Vous avez déjà un compte'}
            linkLabel={'Connectez-vous'}
          />
        </div>
      </div>
    </div>
  )
}
