import { Button } from './ui/button'

const contactFormUrl = import.meta.env.VITE_OLYMPE_CONTACT_URL

interface Props {
  onClick: () => void
}

export function PaymentModalContactFooter({ onClick }: Readonly<Props>) {
  return (
    <footer className={'gap flex flex-col gap-3'}>
      <p className={'text-base leading-5'}>
        <span className={'font-semibold'}>Vous n’avez pas eu assez de temps pour tester Olympe ?</span> <br />
        Écrivez-nous à{' '}
        <a
          href={contactFormUrl}
          className={'underline underline-offset-4 hover:text-label'}
          target={'_blank'}
          rel={'noreferrer'}
        >
          support@olympe.legal
        </a>
      </p>
      <p>
        Pour vous déconnecter,{' '}
        <span className={'hover:cursor-pointer'}>
          <Button
            size={'ghost'}
            className={
              'bg-inherit text-base text-primary underline underline-offset-4 hover:bg-inherit hover:text-label'
            }
            onClick={onClick}
          >
            cliquez ici
          </Button>
        </span>{' '}
        .
      </p>
    </footer>
  )
}
