import { useAppSelector } from '@/store/hooks.ts'
import { selectCurrentUser } from '@/store/auth/authSlice.ts'

export const useCurrentUserHook = () => {
  const FREE_PLAN = 'free'
  const currentUser = useAppSelector(selectCurrentUser)
  const isUserConnected = !!currentUser?.id
  const isFreePlanUser = currentUser?.plan === FREE_PLAN
  const trialDaysRemaining = currentUser?.trialDaysRemaining
  const isOpenPaymentModal = isFreePlanUser && trialDaysRemaining === 0
  return {
    isUserConnected,
    currentUser,
    isOpenPaymentModal,
    isFreePlanUser,
  }
}
