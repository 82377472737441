import { TheIconSuccess } from '../icons/TheIconSuccess.tsx'
import { TheIconExclamationMark } from '../icons/TheIconExclamationMark.tsx'
import { TheIconError } from '../icons/TheIconError.tsx'
import { Toaster } from '../../store/toasterSlice.ts'
import { TheIconHourGlass } from '@/components/icons/TheIconHourGlass.tsx'

type Props = { toasterType: Toaster['toasterType'] }
export function ToasterIcon({ toasterType }: Readonly<Props>) {
  switch (toasterType) {
    case 'success':
      return <TheIconSuccess width={'20'} height={'20'} className={'fill-mintGreen'} />
    case 'trialDays':
      return <TheIconHourGlass width={'20'} height={'20'} className={'stroke-mintGreen'} />
    case 'identified':
      return <TheIconExclamationMark width={'20'} height={'20'} className={'fill-error'} />
    default:
      return <TheIconError width={'20'} height={'20'} className={'fill-error'} />
  }
}
